<template>
	<div class="w-100 cart">
		<b-card-group id="home">
			<b-card
				id="cartBackground"
				class="border-0 bg-transparent mb-0">
				<div
					:class="['xs','sm'].includes(windowWidth) ? 'ml-2' : 'ml-4'"
					class="row"
					style="min-height: 270px">
					<div class="col my-auto">
						<div class="pull-left">
							<div
								style="font-weight: 600;"
								class="text-white big-label mt-5">
								{{ translate('shopping_cart').toUpperCase() }}
							</div>
						</div>
					</div>
				</div>
			</b-card>
		</b-card-group>
		<b-card-group id="our-products">
			<b-card
				:class="['xs','sm'].includes(windowWidth) ? 'py-5' : 'p-5'"
				class="border-0 bg-white mb-0">
				<div
					class="row mx-auto text-center container">
					<div class="col">
						<h5
							v-if="totalVolume && !isAgencySite"
							class="text-center font-weight-normal">
							{{ translate('total_volume') }}: {{ totalVolume }}
						</h5>
						<template>
							<div class="table-responsive">
								<b-table
									id="cart-table"
									:busy="(!productsHasData || loading)"
									:items="tableData"
									:fields="fields"
									:class="(windowWidth !== 'xs' ) ? 'text-nowrap' : ''"
									class="table-bottom-border table-cart"
									stacked="sm"
									striped
									head-variant="blue-cart">
									<template v-slot:cell(product_name)="product">
										<img
											:src="product.item.product_image"
											class="responsive block-inline"
											style="max-width:80px">
									</template>
									<template v-slot:cell(product_code_name)="product">
										{{ product.item.product_code_name }}
										<br>
										<span
											v-if="product.item.replaceable"
											v-b-tooltip.hover
											:title="translate('pick_flavors')"
											class="badge badge-green pointer">
											{{ translate('replaceable') }}
											<i class="fas fa-info-circle" />
										</span>
									</template>
									<template
										v-slot:head(quantity)
										class="w-20">
										{{ translate('quantity') }}
									</template>
									<template v-slot:cell(quantity)="product">
										<div
											class="row"
											:class="{'py-1 px-4 mx-1' : ['xs'].includes(windowWidth)}">
											<div
												v-if="!blackList.includes(product.item.sku)"
												class="col px-2">
												<template v-if="product.item.show_input">
													<div class="row p-cart">
														<div class="col px-2">
															<input
																v-model="qty[product.item.id]"
																style="max-width:100px;"
																class="w-100 mb-1 center-dropdown">
														</div>
													</div>
													<div class="row p-cart">
														<div class="col pr-0 px-2">
															<b-button
																style="max-width:100px"
																class="w-100"
																variant="primary"
																@click="updateQuantity(product.item)">
																{{ translate('update') }}
															</b-button>
														</div>
													</div>
												</template>
												<label v-if="product.item.sku === 'PL-0001'">
													{{ qty[product.item.id] }}
												</label>
												<select
													v-else-if="product.item.show_select"
													v-model="qty[product.item.id]"
													style="max-width:100px;"
													class="w-100 pl-2 center-dropdown"
													@change="qty[product.item.id] < 10 ? updateQuantity(product.item) : handleChange(product.item)">
													<option
														v-for="item in 9"
														:key="item"
														:value="item">
														{{ item }}
													</option>
													<option :value="10">
														10+
													</option>
												</select>
											</div>
											<div
												v-else
												class="col px-2">
												{{ qty[product.item.id] }}
											</div>
										</div>
									</template>
									<template v-slot:cell(actions)="product">
										<b-button
											v-b-tooltip.hover
											:title="translate('delete_thing', { name: translate('product') })"
											variant="secondary"
											@click="removeProduct(product.item)">
											<i
												class="fa fa-trash"
												aria-hidden="true" />
										</b-button>
									</template>
									<div
										slot="table-busy"
										class="text-center white-space-normal">
										<is-loading
											:loading-label="translate('loading')"
											:no-data-label="translate('empty_cart')"
											:loading="loading"
											:has-data="productsHasData"
											:class="windowWidth === 'xs' && !productsHasData ? 'normal-whitespace' : ''" />
									</div>
								</b-table>
							</div>
							<template v-if="productsHasData">
								<div
									v-if="!loading"
									class="row justify-content-end">
									<div
										v-if="showMessages"
										class="col-12 col-md-6 col-lg-7 mb-3">
										<possible-discounts
											:possible-discount-messages="possibleDiscountMessages"
											:free-products-info="freeProductsInfo"
											:promotions="promotions" />
									</div>
									<div class="col-12 col-md-6 col-lg-5">
										<div
											v-for="(totalInfo, key) in cartTotals"
											:key="key"
											class="row no-gutters">
											<div class="col-6 col-md-7 pb-1 text-right">
												<span
													:class="{
														'h5': !cartSmallTextTotals.includes(key),
														'h6': !['xs', 'sm'].includes(windowWidth) && cartSmallTextTotals.includes(key),
														'text-medium': ['xs', 'sm'].includes(windowWidth) && cartSmallTextTotals.includes(key),
													}"
													style="color:#a0a0a0">
													{{ key === 'shipping' ? translate('shipping_and_handling') : translate(key) }}
												</span>
											</div>
											<div class="col-6 col-md-5 text-right">
												<span
													:class="{
														'h5': !cartSmallTextTotals.includes(key),
														'h6': !['xs', 'sm'].includes(windowWidth) && cartSmallTextTotals.includes(key),
														'text-medium': ['xs', 'sm'].includes(windowWidth) && cartSmallTextTotals.includes(key),
														'text-discount': ['discount', 'points'].includes(key),
													}"
													class="gray-dark">
													{{ totalInfo }}
												</span>
											</div>
										</div>
										<hr class="my-2">
										<div class="row no-gutters">
											<div class="col-6 col-md-7 text-right">
												<h4
													style="color:gray;"
													class="mb-0">
													<b>{{ translate('total') }}</b>
												</h4>
											</div>
											<div class="col-6 col-md-5">
												<h4 class="float-right primary-color mb-0">
													<b>{{ cartTotal }}</b>
												</h4>
											</div>
										</div>
										<b-row v-if="exchange.is_needed">
											<b-col class="h6 exchange-text text-right">
												{{ exchange.total }}*
											</b-col>
										</b-row>
									</div>
								</div>
							</template>
						</template>
						<div class="row no-gutters mt-4 justify-content-end">
							<div class="col-12 col-sm-auto d-flex justify-content-end order-1 order-sm-12">
								<div
									v-if="productsHasData"
									class="mb-2 ml-2 btn btn-primary pointer custom-btn custom-primary-btn"
									@click="handleCheckoutRedirect">
									{{ translate('checkout') }}
								</div>
							</div>
							<div class="col-12 col-sm-auto order-12 order-sm-1">
								<div
									:class="windowWidth === 'xs' ? 'mt-4' : 'mb-2'"
									class="btn btn-outline-primary w-auto pointer custom-btn custom-primary-btn"
									@click="$router.push({ name: getRedirectName('Store') })">
									{{ translate('continue_shopping') }}
								</div>
							</div>
						</div>
						<b-row v-if="!loading && productsHasData && exchange.is_needed">
							<b-col class="small exchange-text text-right">
								{{ translate('exchange_disclaimer') }}
							</b-col>
						</b-row>
					</div>
				</div>
			</b-card>
		</b-card-group>
	</div>
</template>

<script>
import EventBus from '@/util/eventBus';
import { trackEvent } from '@/util/GoogleTagManager';
import WindowSizes from '@/mixins/WindowSizes';
import { MIN_QTY, MAX_QTY } from '@/settings/Cart';
import { PRODUCT_NON_CHANGEABLE_QTYS } from '@/settings/Products';
import { NOT_FOUND } from '@/settings/Errors';
import {
	Cart as messages, Tooltip, Grids, Products,
} from '@/translations';
import PossibleDiscounts from '@/components/PossibleDiscounts';
import CartData from '@/mixins/CartData';
import AgencySite from '@/mixins/AgencySite';
import PublicStore from '@/mixins/PublicStore';

const cookie = require('vue-cookie');

export default {
	name: 'Cart',
	components: { PossibleDiscounts },
	messages: [messages, Tooltip, Grids, Products],
	mixins: [WindowSizes, CartData, PublicStore, AgencySite],
	data() {
		return {
			cookie,
			NOT_FOUND,
			blackList: PRODUCT_NON_CHANGEABLE_QTYS,
		};
	},
	computed: {
		loading() {
			return !!this.cart.data.loading;
		},
		tableData() {
			const table = [];
			try {
				const { products } = this.cart.data.response.data.data.attributes;
				products.forEach((item) => {
					const productName = this.translate(item.code_name);
					const product = {
						id: item.item_id,
						quantity: item.qty,
						subtotal: item.sub_total,
						unit_price: item.unit_price,
						product_code_name: productName,
						product_image: item.image,
						sku: item.sku,
						changed: false,
						replaceable: item.replaceable,
					};

					if (!this.isAgencySite) {
						product.total_volume = item.total_volume;
					}

					product.show_input = false;
					product.show_select = true;

					if (product.quantity > 9) {
						product.show_select = false;
						product.show_input = true;
					}

					table.push(product);
				});
			} catch (error) {
				return table;
			}
			return table;
		},
		fields() {
			const fields = [
				{
					key: 'product_name', label: ['xs'].includes(this.windowWidth) ? '' : this.translate('product_name'), class: 'text-center align-middle', tdClass: 'td-th-class', thClass: 'td-th-class',
				},
				{
					key: 'product_code_name', label: this.translate('name'), class: 'text-center align-middle white-space-normal', tdClass: 'td-th-class', thClass: 'td-th-class',
				},
				{
					key: 'unit_price', label: this.translate('unit_price'), class: 'text-center align-middle', tdClass: 'td-th-class', thClass: 'td-th-class',
				},
				{
					key: 'quantity', label: this.translate('quantity'), class: 'text-center align-middle', tdClass: 'td-th-class', thClass: 'td-th-class',
				},
				{
					key: 'total_volume', label: this.translate('total_volume'), class: 'text-center align-middle', tdClass: 'td-th-class', thClass: 'td-th-class',
				},
				{
					key: 'subtotal', label: this.translate('subtotal'), class: 'text-center align-middle', tdClass: 'td-th-class', thClass: 'td-th-class',
				},
				{
					key: 'actions', label: '', class: 'text-center align-middle', tdClass: 'td-th-class', thClass: 'td-th-class',
				},
			];

			if (this.isAgencySite) {
				// Remove total volume
				fields.splice(4, 1);
			}

			return fields;
		},
		// Credit promo
		availablePromo() {
			try {
				return this.creditDiscount.next_promo.has_next_discounts;
			} catch (e) {
				return false;
			}
		},
		// End credit promo
	},
	mounted() {
		if (!this.showPublicStore) {
			this.$router.replace({ name: 'Store' });
			return;
		}
		this.getData();
	},
	methods: {
		getData() {
			let options = {};
			if (this.isAgencySite) {
				const checkDiscounts = this.$replicated.replicatedCheckPromo() ?? false;
				const replicatedSponsor = this.$replicated.replicatedSponsor();
				const replicatedDiscountCode = this.$replicated.replicatedDiscountCode();
				options = {
					sponsor: replicatedSponsor,
					check_discount: checkDiscounts ? 1 : 0,
					discount_code: replicatedDiscountCode,
				};
			}
			this.cart.getCart(cookie.get('cart_id'), options).then((response) => {
				response.attributes.products.forEach((item) => {
					this.$set(this.qty, item.item_id, item.qty);
				});
			}).catch((getCartError) => {
				if (this.NOT_FOUND.includes(getCartError.status)) {
					this.createCart.createCart().then(() => {
						this.cookie.delete('cart_id');
						this.cookie.set('cart_id', this.createCart.data.response.data.response.cart_id, { expired: `${this.EXPIRATION_VALUE}${this.EXPIRATION_TIME}` });

						this.getData();
					}).catch(() => {
						this.alert.toast('error', this.translate('default_error_message'));
					});
				} else {
					this.alert.toast('error', this.translate('default_error_message'));
				}
			});
		},
		async removeProduct(product) {
			this.qty[product.id] = 0;
			await this.updateQuantity(product, true);
		},
		async updateQuantity(product, bypassMinQty = false) {
			try {
				let quantity = bypassMinQty ? 0 : MIN_QTY;

				if (Number.isNaN(this.qty[product.id]) || this.qty[product.id] > MIN_QTY) quantity = this.qty[product.id];
				if (Number.isNaN(this.qty[product.id]) || this.qty[product.id] > MAX_QTY) {
					quantity = MAX_QTY;
					this.alert.toast('info', this.translate('max_qty', { max: MAX_QTY }));
				}
				const products = { [product.sku]: quantity };

				await this.addCartProduct.addCartProducts(cookie.get('cart_id'), { products, replace_qty: true, sponsor_id: this.$replicated.replicatedSponsor() });
				trackEvent(this.$gtm, 'productQtyUpdated'); // TODO: track product quantity and sku
				EventBus.$emit('updateTotals');
				this.getData();
			} catch (e) {
				if (typeof this.addCartProduct.errors.errors.products !== 'undefined') {
					let response = '';
					this.addCartProduct.errors.errors.products.forEach((item) => { response += `${item} \n`; });
					this.alert.toast('error', response, { timer: 4000 });
				} else {
					this.alert.toast('error', this.translate('default_error_message'));
				}
			}
		},
		handleChange(product) {
			this.tableData.forEach((item) => {
				if (item.id === product.id) {
					item.changed = true;
					if (this.qty[product.id] > 9) {
						item.show_input = true;
						item.show_select = false;
					}
				}
			});
		},
		handleCheckoutRedirect() {
			if (this.availablePromo) {
				EventBus.$emit('show-pre-checkout-modal');
			} else {
				this.$router.push({ name: this.getRedirectName('Checkout') }).catch(() => {});
			}
		},
	},
};
</script>
<style>
	@import url('https://fonts.googleapis.com/css?family=Montserrat');
	.big-label{
		font-size: 55px;
	}
	.custom-btn.custom-white-btn{
		border: 1px solid white;
		width: 150px;
		border-radius: 25px;
		background: transparent;
		color: white;
	}
	.custom-btn.custom-white-btn:hover{
		background: white;
		color: black;
	}
	.custom-btn.custom-primary-btn{
		width: 150px;
		border-radius: 25px;
	}
	.table-cart {
		font-family: 'Montserrat', sans-serif !important;
		font-weight: 600 !important;
		border-collapse: collapse;
		width: 100%;
	}
	.table-cart {
		border: 1px solid #dddddd;
		text-align: left;
		padding: 8px;
	}
	.td-th-class {
		border: 1px solid #dddddd;
		text-align: left;
		padding: 8px;
	}
	@media only screen and (max-width: 575px) {
		.responsive {
			width: 100%;
			max-height: 150px;
			max-width: 150px;
		}
		#cartBackground .big-label {
			font-family: 'Montserrat', sans-serif !important;
			font-size: 45px;
		}
	}
	.cart  {
		font-family: 'Montserrat', sans-serif !important;
		font-weight: 600 !important;
	}
</style>
<style scoped>
@import url('https://fonts.googleapis.com/css?family=Montserrat');
	html body div {
		font-family: 'Montserrat', sans-serif !important;
		font-weight: 600 !important;
	}
	p h5 {
		font-family: 'Montserrat', sans-serif !important;
		font-weight: 400 !important;
	}
</style>
